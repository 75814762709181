const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://ol9rwg1y4g.execute-api.ap-southeast-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://vfki8dba2d.execute-api.ap-southeast-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.staging.nsr.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.nsr.forwoodsafety.com',
    WEBSOCKET: 'wss://dvldmv3ov7.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
};

export default config;
